<template>
  <form @submit.prevent="onClickValidate">
    <div class="eui-o-advancedPDFViewer__addZoneHeader">
      <EuiHeading :level="3" v-if="isInEditState">
        {{ $t('paraphers.create.heading.updateZone') }}
      </EuiHeading>
      <EuiHeading :level="3" v-else>
        {{ $t('paraphers.create.heading.addSignatoryField') }}
      </EuiHeading>
      <EuiButton iconOnly color="primary" size="medium" @click="closePopover">
        <EuiIcon name="close"/>
      </EuiButton>
    </div>
    <div class="eui-o-advancedPDFViewer__addZoneContent">
      <EuiGrid class="eui-u-p-0" v-if="localZone">
        <EuiGridRow>
          <EuiGrid v-if="this.localZone.zone.user" class="eui-u-p-0 eui-u-mt-5">
            <EuiGridRow justify="between">
              <EuiGridColumn width="3/12" verticalAlign="center">
                <div class="letterAvatar">{{localZone.zone.user.initials}}</div>
              </EuiGridColumn>
              <EuiGridColumn width="7/12" class="eui-u-pl-1" verticalAlign="center">
                <span class="leading-tight break-words eui-u-font-bold">{{localZone.zone.user.fullname}}</span>
                <span class="leading-tight break-words">{{localZone.zone.user.email}}</span>
              </EuiGridColumn>
              <EuiGridColumn width="2/12" horizontalAlign="end" verticalAlign="center">
                <EuiButton  iconOnly color="primary" size="medium" @click="onClear">
                  <EuiIcon name="delete"/>
                </EuiButton>
              </EuiGridColumn>
            </EuiGridRow>
          </EuiGrid>
          <EuiSearchBar v-else :placeholder="$t('paraphers.searchBar.placeholder')"
                     ref="searchBar"
                     class="eui-u-mt-5"
                     v-model="signerSelectedValue"
                     @change="onSelectItem(signerSelectedValue)"
                     @search="onSelectItem(signerSelectedValue)"
                     :options="signerList"
                     @clear="onClear"
                     autofocus
                     clearable
                     :emptyMessage="$t('paraphers.searchBar.emptyMessage')"
                     :suggestionsTitle="$t('paraphers.searchBar.suggestionsTitle')">
            <template #autocompleteItem="{ item }">
              <EuiGridRow justify="between">
                <EuiGridColumn width="3/12" verticalAlign="center">
                  <div class="letterAvatar">{{item.initials}}</div>
                </EuiGridColumn>
                <EuiGridColumn width="9/12" class="eui-u-pl-1" verticalAlign="center">
                  <span class="leading-tight break-words eui-u-font-bold">{{item.fullname}}</span>
                  <span class="leading-tight break-words">{{item.email}}</span>
                </EuiGridColumn>
              </EuiGridRow>
            </template>
          </EuiSearchBar>
        </EuiGridRow>
        <EuiGridRow>
          <EuiHeading :level="4" class="eui-u-text-sm eui-u-mt-5 eui-u-mb-4">
            {{ $t('paraphers.create.heading.signatureMode') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiRadio v-if="semiAutomaticPositionModeEnabled"
                      class="eui-u-mb-3"
                      v-model="localZone.zone.position.positionType"
                      value="semi-automatic">
              {{ $t('paraphers.create.label.semiAuto') }}
            </EuiRadio>
            <EuiRadio v-if="manualPositionModeEnabled && !onlyManualPositionModeEnabled"
                      v-model="localZone.zone.position.positionType"
                      value="manual">
              {{ $t('paraphers.create.label.manual') }}
            </EuiRadio>
            <EuiTextField :adapt-size="true"
                          @buttonClick="searchCoord"
                          @keydown.enter.prevent="searchCoord"
                          :label="$t('paraphers.create.label.signatoryName')"
                          v-model="wordToFind" trailingIconButton="search"
                          v-if="localZone.zone.position.positionType === 'semi-automatic'"/>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiHeading :level="4" class="eui-u-text-sm eui-u-mt-5 eui-u-mb-4">
            {{ $t('common.advancedParameters') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiCheckbox class="eui-u-mb-2"
                      v-model="localZone.isHidden">
              {{ $t('paraphers.create.label.invisibleSignature') }}
            </EuiCheckbox>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiDivider secondary/>
        <EuiGridRow>
          <EuiGridColumn verticalAlign="center" horizontalAlign="start">
            <EuiButton variant="text" :disabled="isInInitialState" color="primary" class="eui-o-advancedPDFViewer__popoverbutton-secondary" @click="reset">
              {{ $t('button.reset') }}
            </EuiButton>
          </EuiGridColumn>
          <EuiGridColumn verticalAlign="center" horizontalAlign="end">
            <EuiButton variant="raised"
                       type="submit"
                       :disabled="isValidateButtonDisabled"
                       color="primary"
                       class="eui-o-advancedPDFViewer__popoverbutton">
              {{ $t('button.validate') }}
            </EuiButton>
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </div>
  </form>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';

export default {
  name: 'PDFPopoverSignature',
  props: {
    signerList: {
      type: Array,
      default: () => [],
    },
    zone: {
      type: Object,
      default: () => ({}),
    },
    token: {
      type: String,
    },
  },
  watch: {
    'localZone.zone.position.positionType': {
      deep: true,
      handler(val) {
        if (val === 'semi-automatic') {
          this.signerSelectedValue = '';
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      contain: 'ModuleEdocSign/auth/contain',
      onlyManualPositionModeEnabled: 'ModuleEdocSign/auth/onlyManualPositionModeEnabled',
    }),
    isValidateButtonDisabled() {
      return (!this.localZone.zone.user ||
        (this.localZone.zone.user &&
        this.localZone.zone.position.positionType === 'semi-automatic' &&
          (this.token === undefined || this.wordToFind.length === 0 || this.token !== this.wordToFind))
      );
    },
    isInInitialState() {
      return this.signerSelectedValue === '' &&
          this.localZone.zone.position.positionType === 'manual' &&
          this.localZone.signerOnlyValidate === false &&
          this.localZone.isHidden === false;
    },
  },
  data() {
    return {
      isInEditState: false,
      localZone: undefined,
      wordToFind: this.token,
      signerSelectedValue: '',
      semiAutomaticPositionModeEnabled: undefined,
      manualPositionModeEnabled: undefined,
    };
  },
  methods: {
    searchCoord() {
      this.$emit('getWordCoordinates', { wordToFind: this.wordToFind, current: this.localZone });
    },
    reset() {
      this.localZone.zone.position.positionType = 'manual';
      this.localZone.zone.position.token = '';
      this.localZone.signerOnlyValidate = false;
      this.localZone.isHidden = false;
      this.onClear();
      this.clearSearchBar();
    },
    clearSearchBar() {
      if (this.$refs.searchBar) {
        this.$refs.searchBar.clear();
      }
    },
    closePopover() {
      this.$emit('close');
    },
    onClear() {
      this.signerSelectedValue = '';
      this.localZone.zone.user = '';
    },
    onSelectItem(signerToAdd) {
      if (typeof signerToAdd !== 'string') this.localZone.zone.user = signerToAdd;
    },
    onClickValidate() {
      if (!this.isValidateButtonDisabled) {
        this.$emit('add', this.localZone);
      }
    },
    handleKeydown(e) {
      if (e.key === 'Enter') {
        if (e.target.nodeName === 'BODY') {
          this.onClickValidate();
        }
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    this.localZone = cloneDeep(this.zone);
    this.isInEditState = this.localZone.zone.user !== undefined;
    this.semiAutomaticPositionModeEnabled = this.contain('parapherCreationFormPositionTypesAvailable', 'semi-automatic', true);
    this.manualPositionModeEnabled = this.contain('parapherCreationFormPositionTypesAvailable', 'manual', true);
    if (!this.manualPositionModeEnabled && this.semiAutomaticPositionModeEnabled) {
      this.localZone.zone.position.positionType = 'semi-automatic';
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>
