<template>
  <div class="eui-o-advancedPDFViewer__zone"
       :class="{ '-tokenMissing' : position.missToken }"
       :style="styleObject"
       @dragstart="startDrag($event)"
       ref="zone">
    <div class="eui-o-advancedPDFViewer__iconContainer">
      <SignIcon color="primary"/>
    </div>
    <div v-if="$slots.default" ref="text" class="eui-o-advancedPDFViewer__text" v-show="shouldDisplayText">
      <slot/>
    </div>
    <div class="eui-o-advancedPDFViewer__actions">
      <slot name="actions">
        <EuiDropdownMenu>
          <EuiList>
            <EuiListItem :button="true" @click.self="setSize;$emit('update')">
              {{ $t('paraphers.create.label.updateZone') }}
            </EuiListItem>
            <EuiListItem :button="true" @click.self="$emit('delete')">
              {{ $t('button.delete') }}
            </EuiListItem>
          </EuiList>
          <template slot="anchor">
            <EuiButton iconOnly
                       color="primary"
                       size="small"
                       class="eui-o-advancedPDFViewer__actionsAnchor"
                       :style="actionsAnchorStyleObject"
                       @click.stop>
              <EuiIcon name="more_vert"/>
            </EuiButton>
          </template>
        </EuiDropdownMenu>
      </slot>
    </div>
    <div ref="handle" class="handle bottomRight" @mousedown.stop="handleResize($event)">
    </div>
  </div>
</template>

<script>
import SignIcon from '../svg/SignIcon';
import {
  PIXEL_RATIO,
  ZONE_MIN_HEIGHT,
  ZONE_MIN_WIDTH,
} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'PDFZoneSign',
  components: {
    SignIcon,
  },
  watch: {
    scale() {
      this.setSize();
    },
    pagePoint(value) {
      if (value) {
        this.setSize();
      }
    },
  },
  computed: {
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },
    styleObject() {
      const h = Math.min(this.height * 0.8, 50);
      const w = Math.min(h, this.width);
      return {
        '--eui-button-small-iconOnly-height': `${w}px`,
        '--eui-button-small-iconOnly-width': `${w}px`,
        '--eui-button-icon-font-size': `${(w * 22) / 48}px`,
        '--eui-button-icon-line-height': 1.5,
        padding: `0 min(${(this.scale / PIXEL_RATIO) * 8}px, 8px)`,
        left: `${this.x}px`,
        top: `${this.y}px`,
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
      };
    },
    actionsAnchorStyleObject() {
      return {
        'scale': `min(${(this.scale / PIXEL_RATIO) * 1.1}, 1)`,
      };
    },
    pixelSize() {
      const { width: actualSizeWidth, height: actualSizeHeight } = this.viewport;
      const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight]
        .map(dim => Math.ceil(dim / PIXEL_RATIO));
      return { width: pixelWidth, height: pixelHeight };
    },
    shouldDisplayText() {
      return this.width > 70;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
    },
    pagePoint: {
      type: Array,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    viewport: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      resizeOriginal: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    startDrag(evt) {
      try {
        const rect = evt.target.getBoundingClientRect();
        const offsetX = evt.clientX - rect.left;
        const offsetY = evt.clientY - rect.top;
        evt.dataTransfer.setData('offsetX', `${offsetX}`);
        evt.dataTransfer.setData('offsetY', `${offsetY}`);
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('itemID', this.id);
        evt.dataTransfer.setData('zoneType', 'signature');
        this.position.missToken = false;
      } catch (error) {}
    },
    handleResize(evt) {
      evt.preventDefault();
      this.resizeOriginal.x = evt.clientX;
      this.resizeOriginal.y = evt.clientY;
      this.resizeOriginal.width = this.width;
      this.resizeOriginal.height = this.height;
      document.onmouseup = this.reset;
      document.onmousemove = this.resize;
    },
    setSize() {
      const rect = this.actualSizeViewport.convertToViewportRectangle(this.pagePoint);
      this.width = Math.abs(Math.round(rect[2] - rect[0]));
      this.height = Math.abs(Math.round(rect[3] - rect[1]));
      this.x = Math.round(rect[0]);
      this.y = Math.round(rect[1]);
    },
    resize(evt) {
      evt.preventDefault();
      const width = (evt.clientX - this.resizeOriginal.x) + this.resizeOriginal.width;
      const height = (evt.clientY - this.resizeOriginal.y) + this.resizeOriginal.height;
      if (width > ZONE_MIN_WIDTH && (width + this.x) <= this.pixelSize.width) {
        this.width = width;
      }
      if (height > ZONE_MIN_HEIGHT && (height + this.y) <= this.pixelSize.height) {
        this.height = height;
      }
      this.$emit('resize', { id: this.id, x: this.x, y: this.y, width: this.width, height: this.height });
      this.$emit('update:pagePoint', this.actualSizeViewport.convertToPdfPoint(this.x, this.y)
        .concat(this.actualSizeViewport.convertToPdfPoint(this.x + this.width, this.y + this.height)));
    },
    reset() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
  mounted() {
    this.setSize();
  },
};
</script>
