<template>
  <form @submit.prevent @keyup.esc="closePopover">
    <div class="eui-o-advancedPDFViewer__addZoneHeader">
      <EuiHeading :level="3">
        {{ $t('paraphers.create.heading.textField') }}
      </EuiHeading>
      <EuiButton iconOnly color="primary" size="medium" @click="closePopover"><EuiIcon name="close"/></EuiButton>
    </div>
    <div class="eui-o-advancedPDFViewer__addZoneContent">
      <EuiGrid>
        <!-- text -->
        <EuiGridRow>
          <EuiHeading :level="4">
            {{ $t('paraphers.create.heading.associateSignatory') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow v-if="localZone">
          <EuiGrid v-if="localZone.zone.user" class="eui-u-p-0">
            <EuiGridRow justify="between">
              <EuiGridColumn width="3/12" verticalAlign="center">
                <div class="letterAvatar">
                  {{localZone.zone.user.initials}}
                </div>
              </EuiGridColumn>
              <EuiGridColumn width="7/12" class="eui-u-pl-2" verticalAlign="center">
                <span class="leading-tight break-words eui-u-font-bold">{{localZone.zone.user.fullname}}</span>
                <span class="leading-tight break-words">{{localZone.zone.user.email}}</span>
              </EuiGridColumn>
              <EuiGridColumn width="2/12" horizontalAlign="end" verticalAlign="center">
                <EuiButton  iconOnly color="primary" size="medium" @click="onClear">
                  <EuiIcon name="delete"/>
                </EuiButton>
              </EuiGridColumn>
            </EuiGridRow>
          </EuiGrid>
          <EuiSearchBar v-else :placeholder="$t('paraphers.searchBar.placeholder')"
                     ref="searchBar"
                     v-model="signerSelectedValue"
                     @change="onSelectItem(signerSelectedValue)"
                     :options="signerList"
                     :value="signerSelectedValue"
                     autofocus
                     clearable
                     :emptyMessage="$t('paraphers.searchBar.emptyMessage')"
                     :suggestionsTitle="$t('paraphers.searchBar.suggestionsTitle')">
            <template #autocompleteItem="{ item }">
              <EuiGridRow justify="between">
                <EuiGridColumn width="3/12" verticalAlign="center">
                  <div class="letterAvatar">{{item.initials}}</div>
                </EuiGridColumn>
                <EuiGridColumn width="9/12" class="eui-u-pl-1" verticalAlign="center">
                  <span class="leading-tight break-words eui-u-font-bold">{{item.fullname}}</span>
                  <span class="leading-tight break-words">{{item.email}}</span>
                </EuiGridColumn>
              </EuiGridRow>
            </template>
          </EuiSearchBar>
        </EuiGridRow>
        <EuiGridRow>
          <EuiHeading :level="4">
            {{ $t('paraphers.create.heading.fieldTitle') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow>
          <EuiInput ref="zoneTitle" @keyup.enter="onClickValidate" :placeholder="$t('paraphers.create.placeholder.readAndApproved')" v-model="name" />
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn verticalAlign="center" horizontalAlign="start">
            <EuiButton variant="text"
                       @click="reset"
                       class="eui-o-advancedPDFViewer__popoverbutton-secondary">
              {{ $t('button.reset') }}
            </EuiButton>
          </EuiGridColumn>
          <EuiGridColumn verticalAlign="center" horizontalAlign="end">
            <EuiButton :disabled="!canValidate"
                       variant="raised"
                       color="primary"
                       @click="onClickValidate"
                       class="eui-o-advancedPDFViewer__popoverbutton">
              {{ $t('button.validate') }}
            </EuiButton>
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </div>
  </form>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import DOMpurify from 'dompurify';

export default {
  name: 'AddText',
  props: {
    signerList: {
      type: Array,
      default: () => [],
    },
    zone: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localZone: undefined,
      signersSelected: [],
      signerSelectedValue: '',
      name: undefined,
    };
  },
  computed: {
    canValidate() {
      return (this.signerSelectedValue !== '' && this.name && this.name.length > 0);
    },
  },
  methods: {
    reset() {
      this.onClear();
      if (this.$refs.searchBar) {
        this.$refs.searchBar.clear();
      }
      this.name = '';
    },
    closePopover() {
      this.$emit('close');
    },
    onClear() {
      this.signerSelectedValue = '';
      this.localZone.zone.user = '';
    },
    onSelectItem(signerToAdd) {
      this.localZone.zone.user = signerToAdd;
      this.$refs.zoneTitle.$refs.input.focus();
    },
    onClickValidate() {
      if (this.canValidate) {
        this.localZone.zone.name = DOMpurify.sanitize(this.name);
        this.$emit('add', this.localZone);
      }
    },
    handleKeydown(e) {
      if (e.key === 'Enter') {
        if (e.target.nodeName === 'BODY') {
          this.onClickValidate();
        }
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    this.localZone = cloneDeep(this.zone);
    this.signerSelectedValue = this.localZone.zone.user;
    this.name = this.localZone.zone.name;
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>
