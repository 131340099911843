<template>
  <div class="eui-o-pdfPreview">
    <div class="eui-o-pdfPreview__title">
      {{ title }}
    </div>
    <div class="eui-o-pdfPreview__container" :style="styleObject">
      <div class="eui-o-pdfPreview__docsList">
        <div v-for="(item, index) in pdfsList"
             :key="index"
             class="eui-o-pdfPreview__docItem">
          <div class="eui-o-pdfPreview__docTitle"
               @click="toggle(index)">
            <EuiIcon name="file" size="small"/> {{ index + 1 }} - {{ item.name }}
            <EuiIcon name="arrow_right" size="medium"/>
            <EuiBadge inverted color="error" :content="signsListWithMissingTokensByDoc(item.id).length" style="position: absolute;right: 62px;"/>
            <EuiBadge inverted color="success" :content="signsListByDoc(item.id).length" style="position: absolute;right: 38px;"/>
          </div>
        </div>
      </div>
      <div class="eui-o-pdfPreview__details">
        <div class="eui-o-pdfPreview__docTitle"
             @click="toggle(undefined)">
          <EuiIcon name="arrow_left" size="medium"/>
          <EuiIcon name="file" size="small"/>
          <template v-if="pdfsList[selectedItem]">
            {{ selectedItem + 1 }} - {{ pdfsList[selectedItem].name }}
            <EuiBadge inverted color="error" :content="signsListWithMissingTokensByDoc(pdfsList[selectedItem].id).length" style="position: absolute;right: 48px;"/>
            <EuiBadge inverted color="success" :content="signsListByDoc(pdfsList[selectedItem].id).length" style="position: absolute;right: 24px;"/>
          </template>
        </div>
        <ScrollingDocument class="eui-o-pdfPreview__pages"
                           :ref="`scrollingDocument-${uid}`"
                           :pages="pages"
                           v-bind="{pageCount}"
                           :currentPage="currentPage"
                           v-slot="{page, isPageFocused}"
                           :singlePage="false"
                           isPreview>
          <PDFThumbnail class="eui-o-pdfPreview__page"
                        v-bind="{scale, page, isPageFocused}"
                        :signsCount="getSignsCount(page.pageNumber) + getParaphsCount()"
                        :page="page"
                        @page-focus="onPageFocused"/>
        </ScrollingDocument>
      </div>
    </div>
  </div>

</template>

<script>
import ScrollingDocument from '@silae/edoc-ui/src/components/organisms/PDFViewer/_internal/ScrollingDocument';
import PDFThumbnail from '@silae/edoc-ui/src/components/organisms/PDFViewer/_internal/PDFThumbnail';

export default {
  name: 'PDFPreview',
  computed: {
    styleObject() {
      return {
        'margin-left': this.showDetails ? '-100%' : '0%',
      };
    },

    uid() {
      return this._uid;
    },
  },
  components: {
    ScrollingDocument,
    PDFThumbnail,
  },
  data() {
    return {
      showDetails: false,
      selectedItem: 0,
    };
  },
  props: {
    title: {
      type: String,
    },
    pdfsList: {
      type: Array,
      default: () => [],
    },
    scale: {
      type: Number,
      default: 1.0,
    },
    pages: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    currentPdfIndex: {
      type: Number,
      default: 0,
    },
    signsList: {
      required: false,
      default: () => [],
    },
  },

  methods: {
    getParaphsCount() {
      return this.signsList.filter((sign) => sign.zone.position.docId === this.pdfsList[this.currentPdfIndex].id && sign.zone.zoneType === 'paraph').length;
    },

    getSignsCount(pageNumber) {
      return this.signsList.filter((sign) => sign.zone.position.docId === this.pdfsList[this.currentPdfIndex].id && sign.zone.zoneType !== 'paraph' && sign.zone.position.page === pageNumber).length;
    },

    onPageFocused(pageNumber) {
      this.$emit('update:currentPage', pageNumber);
      this.$emit('page-change', pageNumber);
    },

    signsListByDoc(docId) {
      return this.signsList.filter((sign) => sign.zone.position.docId === docId).filter((item) => !item.zone.position.missToken);
    },

    signsListWithMissingTokensByDoc(docId) {
      return this.signsList.filter((sign) => sign.zone.position.docId === docId).filter((item) => item.zone.position.missToken);
    },

    toggle(index) {
      if (index === undefined) {
        this.showDetails = false;
      } else {
        if (index !== this.currentPdfIndex) {
          this.$emit('update:pages', undefined);
        }
        this.$emit('update:currentPdfIndex', index);
        this.selectedItem = index;
        this.showDetails = true;
      }
    },
  },
};
</script>
