<template>
  <!--
  dragging: should be available for the order within the band
  resize: no resize for zones within the band
  -->
  <div class="eui-o-advancedPDFViewer__zone eui-u-flex -initialZone anchor"
       ref="zone"
       :style="styleObject">
    <div v-if="$slots.default" class="eui-o-advancedPDFViewer__text">
      <slot/>
    </div>
    <div class="eui-o-advancedPDFViewer__actions">
      <slot name="actions">
        <EuiDropdownMenu :open.sync="isDropdownOpen">
          <EuiList>
            <EuiListItem :button="true" @click.self="$emit('update', $event)">{{ $t('paraphers.create.label.updateZone') }}</EuiListItem>
            <EuiListItem :button="true" @click.self="$emit('delete')">{{ $t('button.delete') }}</EuiListItem>
          </EuiList>
          <template #anchor>
            <EuiButton iconOnly
                       color="primary"
                       size="small"
                       class="eui-o-advancedPDFViewer__actionsAnchor"
                       @click="isDropdownOpen = true"
                       :style="actionsAnchorStyleObject">
              <EuiIcon name="more_vert"/>
            </EuiButton>
          </template>
        </EuiDropdownMenu>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  PIXEL_RATIO,
  ZONE_INITIALS_TRIGGER_HEIGHT,
  ZONE_INITIALS_TRIGGER_WIDTH,
} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'PDFZoneInitials',
  props: {
    pagePoint: {
      type: Array,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    viewport: {
      type: Object,
      required: true,
    },
    closeDropdown: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    scale() {
      this.setSize();
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
  },
  data() {
    return {
      id: null,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      isDropdownOpen: false,
    };
  },
  computed: {
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },
    styleObject() {
      return {
        left: `${this.x}px`,
        top: `${this.y}px`,
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
      };
    },
    shouldDisplayDrag() {
      return this.height > ZONE_INITIALS_TRIGGER_HEIGHT && this.width > ZONE_INITIALS_TRIGGER_WIDTH;
    },
    actionsAnchorStyleObject() {
      return {
        'scale': `min(${(this.scale / PIXEL_RATIO) * 1.1}, 1)`,
      };
    },
  },
  methods: {
    setSize() {
      const rect = this.actualSizeViewport.convertToViewportRectangle(this.pagePoint);
      this.width = Math.abs(Math.round(rect[2] - rect[0]));
      this.height = Math.abs(Math.round(rect[3] - rect[1]));
      this.x = Math.round(rect[0]);
      this.y = Math.round(rect[1]);
    },
  },
  mounted() {
    this.setSize();
  },
};
</script>
