<template>
  <div class="eui-o-advancedPDFViewer__band"
       @dragstart="startDrag($event)"
       :style="styleObject"
       ref="band">
    <div @dragstart="startDrag($event)"
         :style='actionsAnchorStyleObject'>
      <EuiIcon name="nouveaudrag"
               color="primary"
               class="eui-o-advancedPDFViewer__dragZone"
               :class="{ '-grabbing': dragging }"/>
    </div>
    <div :class="`eui-o-advancedPDFViewer__paraphZone -${align}`"
         @dragstart.self="startDrag($event)">
      <EuiList draggable :options="paraphZones" @draggable:end="sortParaphUsers">
        <EuiTooltip v-for="(zone, zoneIndex) in paraphZones"
                    :key="`zone-${zoneIndex}`"
                    :content="zone.zone.user.fullname"
                    placement="top"
                    :forceShow="focusStyle(zone.zone.user.userId) === '-focus'">
          <template slot="anchor">
            <PDFZoneInitials :class="[`${focusStyle(zone.zone.user.userId)}`]"
                             @delete="deleteZone(zone)"
                             :scale="scale"
                             :viewport="viewport"
                             :pagePoint="zone.zone.pagePoint"
                             :ref="'userParaph'+zoneIndex"
                             @mousedown="$event.preventDefault()"
                             @update="$emit('update', $event)"
                             :closeDropdown="closeDropdown">
              {{ zone.zone.user.initials }}
            </PDFZoneInitials>
          </template>
        </EuiTooltip>

      </EuiList>
    </div>
  </div>
</template>

<script>
import PDFZoneInitials from './PDFZoneInitials';
import {PIXEL_RATIO} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'PDFBand',
  components: { PDFZoneInitials },
  data() {
    return {
      dragging: false,
      sortedZones: [],
      startPosition: 0,
      endPosition: 0,
      previousPosition: 0,
      height: 40,
      pdfTop: 0,
      closeDropdown: false,
    };
  },
  watch: {
    scale() {
      this.setSize();
    },
    pagePoint() {
      this.setSize();
    },
    zones: {
      handler(val) {
        this.pdfTop = val[0].zone.position.y * 100;
        this.endPosition = val[0].zone.drag.end;
        this.previousPosition = val[0].zone.drag.end;
        this.startPosition = val[0].zone.drag.start;
      },
      deep: true,
    },
  },
  props: {
    zones: {
      type: Array,
      required: true,
    },
    align: {
      type: String,
      required: false,
      default: 'right',
      validator(value) {
        return ['right', 'center', 'left'].indexOf(value) !== -1;
      },
    },
    id: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: false,
    },
    scale: {
      type: Number,
      default: 1,
      required: true,
    },
    viewport: {
      type: Object,
      required: true,
    },
    pagePoint: {
      type: Array,
      required: true,
    },
    signatoriesToDisplay: {
      default: undefined,
    },
  },
  computed: {
    actionsAnchorStyleObject() {
      return {
        'scale': `min(${(this.scale / PIXEL_RATIO) * 1.1}, 1)`,
      };
    },
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },
    styleObject() {
      return {
        top: `calc(${this.absoluteTop}% - ${this.height}px)`,
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
      };
    },
    iconStyleObject() {
      return {
        height: `${this.height / 1.5}px`,
        width: `${this.height / 1.5}px`,
      };
    },
    absoluteTop() {
      return 100 - this.pdfTop;
    },
    paraphZones() {
      return this.zones.filter(item => item.zone.zoneType === 'paraph');
    },
  },
  methods: {
    focusStyle(userId) {
      if (this.signatoriesToDisplay === userId) return '-focus';
      else if (this.signatoriesToDisplay === undefined) return '';
      else return '-below';
    },
    startDrag(evt) {
      try {
        this.closeDropdown = !this.closeDropdown;
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('itemID', this.id);
        evt.dataTransfer.setData('zoneType', 'paraph');
        const rect = evt.target.getBoundingClientRect();
        const offsetY = evt.clientY - rect.top;
        evt.dataTransfer.setData('offsetY', `${offsetY}`);
      } catch (error) {}
    },
    setSize() {
      const rect = this.actualSizeViewport.convertToViewportRectangle(this.pagePoint);
      this.height = Math.abs(Math.round(rect[3] - rect[1]));
    },
    deleteZone(zone) {
      this.$emit('delete', zone);
    },
    reset() {
      this.dragging = false;
      document.onmouseup = null;
      document.onmousemove = null;
    },
    sortParaphUsers(newOrder) {
      this.$emit('sort-paraphs', newOrder);
    },
  },
  mounted() {
    this.pdfTop = this.zones[0].zone.position.y * 100;
    this.setSize();
  },
};
</script>
