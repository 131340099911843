<template>
  <div class="eui-o-advancedPDFViewer">
    <EuiOverlay :visible="isPopoverGuideOpened"/>
    <EuiPDFViewer :pdf="pdf"
                  ref="pdfViewer"
                  :title="title"
                  :currentPage="currentPage"
                  @dropZone="onDropZone($event)"
                  @document-rendered="onDocumentRendered"
                  @page-count="updatePageCount"
                  @scale-change="updateScale"
                  @page-focus="updateCurrentPage"
                  @page-click="addZone"
                  @update:currentPage="updateCurrentPage"
                  base64
                  pagination
                  :class="{'-lock' : this.showPopover}"
                  :buffer="buffer">
      <template v-for="index in pageCount" v-slot:[`zone-${index}`]>
        <template v-if="index === 1">
          <EuiPopover :open="isPopoverGuideOpened"
                      class="-dontShowAgain"
                      strategy="fixed"
                      :key="`popover-dontShowAgain-${index}`"
                      :dontShowAgain="isPopoverGuideOpened"
                      :closeButtonText="$t('paraphers.create.addSignatures.guide.closeButtonText')"
                      :dontShowAgainText="$t('paraphers.create.addSignatures.guide.dontShowAgainText')"
                      placement="top"
                      @close="isPopoverGuideOpened = false"
                      @dontShowAgain="dontShowAgain">
            <div class="eui-u-pt-3">
              <strong>{{$t('paraphers.create.addSignatures.guide.message') }}</strong>
            </div>
            <template #input>
              <div style="position: absolute; left: 50%; top: 5%;">&nbsp;</div>
            </template>
          </EuiPopover>
        </template>
        <template v-if="index === position.page">
          <EuiPopover v-if="showPopover"
                      :key="`popover-${index}`"
                      :footer="false"
                      :open.sync="showPopover"
                      strategy="fixed"
                      ref="popover"
                      @referenceHidden="onReferenceHidden"
                      :placement="popoverContent === 0 ? 'right' : 'top'">
            <div class="eui-o-advancedPDFViewer__addZoneContainer">
              <AddZoneContainer v-if="popoverContent === 0"
                                @addCaptiveZone="onAddCaptiveZone"
                                @close="onClose"/>
              <AddSignature v-else-if="popoverContent === 1"
                            :zone="currentZone"
                            :signerList="suggestions"
                            :token="currentZone.zone.position.token ? currentZone.zone.position.token : position.token"
                            @getWordCoordinates="searchCoord"
                            @add="onAddSignatureZone"
                            @close="onClose"/>
              <AddText v-else-if="popoverContent === 3"
                       :zone="currentZone"
                       :signerList="suggestions"
                       @add="onAddTextZone"
                       @close="closePopover"/>
            </div>
            <template #input>
              <div class="eui-o-advancedPDFViewer__signPosition" v-bind="signAttrs" />
              <!-- target to point where the user clicked -->
              <svg width="8" height="8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind="signAnchor">
                <path d="M5.455 7.273H4.364V.727h1.09a.364.364 0 0 0 0-.727H2.547a.364.364 0 0 0 0 .727h1.09v6.546h-1.09a.364.364 0 0 0 0 .727h2.909a.364.364 0 0 0 0-.727Z" fill="#000"/>
                <path d="M7.273 2.374v1.09H.727v-1.09a.364.364 0 0 0-.727 0v2.909a.364.364 0 0 0 .727 0V4.192h6.546v1.09a.364.364 0 0 0 .727 0V2.375a.364.364 0 0 0-.727 0Z" fill="#000"/>
              </svg>
            </template>
          </EuiPopover>
        </template>
        <EuiTooltip v-for="(item, zoneIndex) in signatureZones.filter(item => (item.zone.position.page === index && item.zone.pagePoint !== undefined))"
                    :content="item.zone.user.fullname"
                    placement="top"
                    :forceShow="focusStyle(item.zone.user.userId) === '-focus'"
                    :key="`zone-sign-${zoneIndex}`">
          <template slot="anchor">
            <PDFZoneSign
                :id="item.id"
                :ref="`zone-${item.id}`"
                :class="[`zone-${item.id}`, styleSign(item), `${focusStyle(item.zone.user.userId)}`]"
                :page.sync="item.zone.position.page"
                :pageCount="pageCount"
                :scale.sync="scale"
                :viewport="getViewport(index)"
                :pagePoint.sync="item.zone.pagePoint"
                :position="item.zone.position"
                :draggable="!showPopover"
                @resize="resizeSignZone"
                @update="onUpdateSignZone(item)"
                @delete="deleteZone(item)">
              {{ item.zone.user.fullname }}
            </PDFZoneSign>
          </template>
        </EuiTooltip>
        <EuiTooltip v-for="(item, zoneIndex) in textZones.filter(item => (item.zone.position.page === index))"
                    :content="item.zone.user.fullname"
                    placement="top"
                    :forceShow="focusStyle(item.zone.user.userId) === '-focus'"
                    :key="`zone-text-${zoneIndex}`">
          <template slot="anchor">
            <PDFZoneText :id="item.id"
                         :ref="`zone-${item.id}`"
                         :class="[`zone-${item.id}`, `${focusStyle(item.zone.user.userId)}`]"
                         :page.sync="item.zone.position.page"
                         :pageCount="pageCount"
                         :scale.sync="scale"
                         :viewport="getViewport(index)"
                         :pagePoint.sync="item.zone.pagePoint"
                         :draggable="!showPopover"
                         @resize="resizeSignZone"
                         @duplicate="onDuplicate(item)"
                         @update="onUpdateTextZone(item)"
                         @delete="deleteZone(item)">
              {{ item.zone.name }}
            </PDFZoneText>
          </template>
        </EuiTooltip>
        <template v-if="paraphZones.length">
          <PDFBand :key="`band-${index}`"
                   :zones.sync="bandZones"
                   :align="bandAlign"
                   :id="paraphZones[0].id"
                   @delete="deleteZone"
                   :scale.sync="scale"
                   :viewport="getViewport(index)"
                   :pagePoint.sync="paraphZones[0].zone.pagePoint"
                   @sort-paraphs="sortParaphs"
                   :draggable="!showPopover"
                   :signatoriesToDisplay="signatoriesToDisplay"
                   @update="onUpdateParaph(paraphZones, getViewport(index), paraphZones[0].zone.pagePoint)">
          </PDFBand>
        </template>
      </template>
      <template #header>
        <EuiButton iconOnly size="large" color="primary" @click="$emit('enlarge')">
          <span v-if="!isEnlarge">
            <EuiIcon name="enlarge"/>
          </span>
          <span v-else>
            <EuiIcon name="close"/>
          </span>
        </EuiButton>
      </template>
    </EuiPDFViewer>
    <EuiDialog :open.sync="isParaphEditorOpen" noMargin>
      <AddInitials :paraphsSelected="bandZones"
                   :bandAlign="bandAlign"
                   :allParaphs="suggestions"
                   @addInitialsZone="onAddInitialsZone"
                   @close="isParaphEditorOpen = false"/>
    </EuiDialog>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import AddInitials from './_internal/popovers/AddInitials';
import AddSignature from './_internal/popovers/AddSignature';
import AddText from './_internal/popovers/AddText';
import AddZoneContainer from './_internal/popovers/AddZoneContainer';
import PDFBand from './_internal/zones/PDFBand';
import EuiPDFViewer from '@silae/edoc-ui/src/components/organisms/PDFViewer/PDFViewer';
import PDFZoneSign from './_internal/zones/PDFZoneSign';
import PDFZoneText from './_internal/zones/PDFZoneText';
import _isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import preferences from '../../utils/preferences';
import {
  PIXEL_RATIO,
  ZONE_DEFAULT_HEIGHT,
  ZONE_DEFAULT_WIDTH,
  ZONE_TEXT_DEFAULT_HEIGHT,
  ZONE_TEXT_DEFAULT_WIDTH,
  ZONE_INITIALS_DEFAULT_HEIGHT,
  ZONE_INITIALS_DEFAULT_WIDTH,
} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'AdvancedPDFViewer',
  components: { AddInitials, AddText, AddSignature, AddZoneContainer, PDFBand, EuiPDFViewer, PDFZoneSign, PDFZoneText },
  data() {
    return {
      isPopoverGuideOpened: undefined,
      errors: [],
      scale: undefined,
      currentZone: undefined,
      pageCount: undefined,
      isPreviewEnabled: false,
      pages: undefined,
      showPopover: false,
      popoverContent: 0,
      position: {
        x: 0,
        y: 0,
        page: 1,
        token: undefined,
      },
      previousZone: undefined,
      paraphsSelected: [],
      isParaphEditorOpen: false,
      bandAlign: 'center',
    };
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    id: {
      type: String,
      required: true,
    },
    pdf: {
      type: String,
      required: true,
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    zones: {
      type: Array,
      default: () => [],
    },
    scrollToZone: {
      type: [Number, String],
    },
    base64: {
      type: Boolean,
      default: false,
    },
    isEnlarge: {
      type: Boolean,
      default: false,
    },
    buffer: {
      type: Boolean,
      default: true,
    },
    signatoriesToDisplay: {
      default: undefined,
    },
    shouldUpdatePosition: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    scrollToZone(value) {
      if (value) {
        this.scrollToClass(`zone-${value}`);
        this.$emit('update:scrollToZone', undefined);
      }
    },
    pdf() {
      if (this.paraphZones.length) this.bandAlign = this.paraphZones[0].zone.position.alignment;
      this.closePopover();
    },
    viewport: {
      handler(newValue, oldValue) {
        if (oldValue === null && newValue !== null) {
          this.error = [];
          for (let i = 0; i < this.zones.length; i++) {
            if (this.zones[i].zone.position.docId === this.id) {
              if (this.zones[i].zone.position.page > this.pageCount) {
                this.errors.push({
                  message: `La position pour l'utilisateur ${this.zones[i].zone.user.fullname} en page ${this.zones[i].zone.position.page} est en erreur`,
                });
                this.zones[i].zone.position.page = this.pageCount;
              }
            }
          }
          if (this.errors.length > 0) {
            this.$emit('errors', this.errors);
            this.error = [];
          }
        }
      },
    },
    isPopoverGuideOpened(value) {
      const layoutMain = document.getElementsByClassName('eui-o-layout__main')[0];
      if (value) {
        layoutMain.style.overflow = 'hidden';
      } else {
        layoutMain.style.overflow = 'auto';
      }
      const layout = document.getElementsByClassName('eui-o-layout')[0];
      if (value) {
        layout.classList.add('-overlayed');
      } else {
        layout.classList.remove('-overlayed');
      }
    },
  },
  computed: {
    ...mapState({
      routeName: (state) => state.route.name,
    }),
    defaultSizeSign() {
      return { height: Math.round((ZONE_DEFAULT_HEIGHT * this.scale) / PIXEL_RATIO), width: Math.round((ZONE_DEFAULT_WIDTH * this.scale) / PIXEL_RATIO) };
    },
    defaultSizeText() {
      return { height: Math.round((ZONE_TEXT_DEFAULT_HEIGHT * this.scale) / PIXEL_RATIO), width: Math.round((ZONE_TEXT_DEFAULT_WIDTH * this.scale) / PIXEL_RATIO) };
    },
    defaultSizeInitials() {
      return {
        height: Math.round((ZONE_INITIALS_DEFAULT_HEIGHT * this.scale) / PIXEL_RATIO),
        width: Math.round((ZONE_INITIALS_DEFAULT_WIDTH * this.scale) / PIXEL_RATIO),
      };
    },
    paraphZones() {
      return this.zones.filter(item => (item.zone.position.docId === this.id && item.zone.zoneType === 'paraph' && item.zone.pagePoint));
    },
    signatureZones() {
      return this.zones.filter(item => (item.zone && item.zone.position.docId === this.id && item.zone.zoneType === 'signature'));
    },
    textZones() {
      return this.zones.filter(item => (item.zone && item.zone.position.docId === this.id && item.zone.zoneType === 'textfield' && item.zone.position.viewport));
    },
    viewport() {
      this.$emit('update:viewport', this.getViewport(this.currentPage));
      return this.getViewport(this.currentPage);
    },
    signAttrs() {
      return { style: `position: absolute; left: ${this.position.x}px; top: ${this.position.y}px;` };
    },
    signAnchor() {
      return { style: `position: absolute; left: ${this.position.x - 4}px; top: ${this.position.y - 4}px;` };
    },
    bandZones() {
      return this.paraphZones.filter((zone) => zone.zone.position.docId === this.id);
    },
  },
  methods: {
    ...mapActions({
      searchFieldOnPDF: 'ModuleEdocSign/parapheurs/searchFieldOnPDF',
    }),
    focusStyle(userId) {
      if (this.signatoriesToDisplay === userId) return '-focus';
      else if (this.signatoriesToDisplay === undefined) return '';
      else return '-below';
    },

    styleSign(item) {
      return {
        '-semiAuto': item.zone.position.positionType === 'semi-automatic',
        '-hidden': item.isHidden === true,
      };
    },
    scrollToClass(className) {
      const el = this.$el.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    },
    dontShowAgain() {
      this.isPopoverGuideOpened = false;
      preferences.dontShowAddSignatureGuideAgain();
    },
    async searchCoord(payload) {
      const response = await this.searchFieldOnPDF({ wordToFind: payload.wordToFind, fileContent: this.pdf });
      if (response.coordinates === null) {
        this.$emit('errors', [{ message: this.$t('paraphers.create.addSignatures.error.semiAutoTermNotFound.message') }]);
        return;
      }
      this.closePopover();
      this.updateCurrentPage(response.coordinates.page);
      const pdfRectangle = [
        response.coordinates.x,
        response.coordinates.y + response.coordinates.height,
        response.coordinates.x + ZONE_DEFAULT_WIDTH,
        response.coordinates.y + response.coordinates.height + ZONE_DEFAULT_HEIGHT];
      const { x, y, width, height, pagePoint } = this.getZoneViewportPosition(pdfRectangle, response.coordinates.page);
      this.position.x = x;
      this.position.y = y;
      this.position.page = response.coordinates.page;
      this.position.token = payload.wordToFind;
      this.position.positionType = 'semi-automatic';

      const index = this.zones.findIndex(elem => elem.id === payload.current.id);
      if (index >= 0 && this.previousZone === undefined) {
        this.previousZone = JSON.parse(JSON.stringify(payload.current));
      }

      payload.current.zone.position.page = this.currentPage;
      payload.current.zone.position.x = this.position.x;
      payload.current.zone.position.y = this.position.y;
      payload.current.zone.position.width = width;
      payload.current.zone.position.height = height;
      payload.current.zone.position.token = this.position.token;
      payload.current.zone.pagePoint = pagePoint;

      if (index >= 0) {
        this.$set(this.zones, index, payload.current);
        this.currentZone = payload.current;
      } else if (this.previousZone !== undefined) {
        const index1 = this.zones.findIndex(elem => elem.id === this.previousZone.id);
        this.$set(this.zones, index1, payload.current);
        this.currentZone = payload.current;
      } else {
        this.currentZone = this.createSignature(payload.current.zone.user, payload.current.isHidden);
      }
      await this.delay(200);
      this.popoverContent = 1;
      this.showPopover = true;
      await this.delay(200);
      this.scrollToClass('eui-o-advancedPDFViewer__signPosition');
    },
    checkZone(x, y, width, height, viewport, cut = false) {
      if (x < 0) {
        x = 0;
      }
      if (x + width > Math.floor(viewport.width / PIXEL_RATIO)) {
        if (cut) {
          width = Math.floor(viewport.width / PIXEL_RATIO) - x;
        } else if (x === 0) {
          width = Math.floor(viewport.width / PIXEL_RATIO);
        } else {
          x = Math.floor(viewport.width / PIXEL_RATIO) - width;
        }
      }
      if (y < 0) {
        y = 0;
      }
      if (y + height > Math.floor(viewport.height / PIXEL_RATIO)) {
        if (cut) {
          height = Math.floor(viewport.height / PIXEL_RATIO) - y;
        } else if (y === 0) {
          height = Math.floor(viewport.height / PIXEL_RATIO);
        } else {
          y = Math.floor(viewport.height / PIXEL_RATIO) - height;
        }
      }
      return { x, y, width, height };
    },
    getZoneViewportPosition(pdfRectangle, page) {
      const viewport = this.getViewport(page);
      if (viewport) {
        let { x, y, width, height } = this.convertPdfRectangleToViewportPosition(viewport, pdfRectangle);
        x = x / PIXEL_RATIO;
        y = y / PIXEL_RATIO;
        width = width / PIXEL_RATIO;
        height = height / PIXEL_RATIO;
        const { x: newX, y: newY, width: newWidth, height: newHeight } = this.checkZone(x, y, width, height, viewport, true);
        return {
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight,
          pagePoint: this.getPagePoint(newX, newY, newWidth, newHeight, viewport),
        };
      }
      return null;
    },
    onDropZone(evt) {
      const index = this.zones.findIndex(item => item.zone && item.zone.position.docId === this.id && item.zone.zoneType === evt.type && item.id === evt.id);
      if (index >= 0) {
        const viewport = this.getViewport(evt.page);
        const rect = viewport.convertToViewportRectangle(this.zones[index].zone.pagePoint);
        let width = Math.abs(Math.round(rect[2] - rect[0]));
        let height = Math.abs(Math.round(rect[3] - rect[1]));

        const { x: newX, y: newY, width: newWidth, height: newHeight } = this.checkZone(evt.position.x, evt.position.y, width, height, viewport, false);

        const pagePoint = this.getPagePoint(newX, newY, newWidth, newHeight, viewport);

        if (this.zones[index].zone.zoneType !== 'paraph') {
          this.$set(this.zones[index].zone.position, 'positionType', 'manual');
          this.$set(this.zones[index].zone.position, 'token', undefined);
          this.$set(this.zones[index].zone.position, 'x', newX);
          this.$set(this.zones[index].zone.position, 'y', newY);
          this.$set(this.zones[index].zone.position, 'width', newWidth);
          this.$set(this.zones[index].zone.position, 'height', newHeight);
          this.$set(this.zones[index].zone.position, 'page', evt.page);
          this.$set(this.zones[index].zone.position, 'viewport', viewport);
          this.$set(this.zones[index].zone, 'pagePoint', pagePoint);
          this.$emit('update:zones', this.zones);
        } else {
          this.zones.filter(zone => zone.zone.zoneType === 'paraph').forEach(zone => {
            const height = Math.abs(Math.round(rect[3] - rect[1]));
            if (evt.position.y + height >= this.viewport.height) {
              zone.zone.position.y = 0;
            } else if (evt.position.y + height <= 0) {
              zone.zone.position.y = 1;
            } else {
              const viewportHeight = this.viewport.height / PIXEL_RATIO;
              zone.zone.position.y = Math.round(((viewportHeight - newY - height) * 100) / viewportHeight) / 100;
            }
            this.$emit('update:zones', this.zones);
          });
        }
      }
    },
    getViewport(page, scale = this.scale) {
      if (page && this.pages && this.pages[page - 1] && scale) {
        let viewport = this.pages[page - 1].getViewport({ scale: scale });
        if (viewport.rotation === 90 || viewport.rotation === 270) {
          viewport = viewport.clone({ rotation: 0 });
          const width = viewport.width;
          viewport.width = viewport.height;
          viewport.height = width;
          const xMax = viewport.viewBox[3];
          const yMax = viewport.viewBox[2];
          viewport.viewBox[2] = xMax;
          viewport.viewBox[3] = yMax;
          viewport.transform[5] = viewport.height;
        }
        if (viewport.rotation !== 0) {
          viewport = viewport.clone({ rotation: 0 });
        }
        return viewport;
      }
      return null;
    },
    onDocumentRendered(pages) {
      this.pages = pages;
      this.$emit('document-rendered', pages);
    },
    updatePageCount(pageCount) {
      this.pageCount = pageCount;
      this.$emit('page-count', pageCount);
    },
    updateCurrentPage(currentPage) {
      this.$emit('update:currentPage', currentPage);
    },
    convertPdfRectangleToViewportPosition(viewport, pdfRectangle) {
      const rect = viewport.convertToViewportRectangle(pdfRectangle);
      const height = Math.abs(Math.round(rect[3] - rect[1]));
      return {
        x: Math.round(rect[0]),
        y: Math.round(rect[1]),
        height: height,
        width: Math.abs(Math.round(rect[2] - rect[0])),
      };
    },
    convertToViewportPosition(viewport, x, y, width, height) {
      const bottomLeftPoint = viewport.convertToViewportPoint(x, y + height);
      const topRightPoint = viewport.convertToViewportPoint(x + width, y);
      return {
        x: bottomLeftPoint[0],
        y: bottomLeftPoint[1],
        height: topRightPoint[1] - bottomLeftPoint[1],
        width: topRightPoint[0] - bottomLeftPoint[0],
      };
    },
    setPosition(scale = this.scale) {
      for (let i = 0; i < this.zones.length; i++) {
        if ((this.shouldUpdatePosition || (this.zones[i].zone.pagePoint === undefined &&
            (this.zones[i].zone.position.x !== undefined &&
                this.zones[i].zone.position.y !== undefined))) && !this.zones[i].hasCoord &&
            this.zones[i].zone.position.docId === this.id) {
          if (this.zones[i].zone.zoneType !== 'paraph') {
            try {
              const viewport = this.getViewport(this.zones[i].zone.position.page);
              if (viewport !== null) {
                const { x, y, width, height } = this.convertToViewportPosition(
                  viewport,
                  this.zones[i].zone.position.x,
                  this.zones[i].zone.position.y,
                  this.zones[i].zone.position.width,
                  this.zones[i].zone.position.height);
                this.zones[i].zone.position.x = x / PIXEL_RATIO;
                this.zones[i].zone.position.y = y / PIXEL_RATIO;
                this.zones[i].zone.position.height = height / PIXEL_RATIO;
                this.zones[i].zone.position.width = width / PIXEL_RATIO;
                this.$set(this.zones[i].zone.position, 'viewport', viewport);
                this.$set(this.zones[i].zone, 'pagePoint', this.getPagePoint(
                  this.zones[i].zone.position.x,
                  this.zones[i].zone.position.y,
                  this.zones[i].zone.position.width,
                  this.zones[i].zone.position.height,
                  viewport));
                this.zones[i].hasCoord = true;
              }
            } catch (e) {
              console.log('setPosition error');
            }
          } else {
            try {
              const viewport = this.pages[this.zones[i].zone.position.page - 1].getViewport({ scale: scale });
              this.$set(this.zones[i].zone.position, 'y', this.zones[i].zone.position.y / PIXEL_RATIO);
              this.$set(this.zones[i].zone.position, 'viewport', viewport);
              this.$set(this.zones[i].zone, 'pagePoint', this.getPagePoint(
                this.zones[i].zone.position.x,
                this.zones[i].zone.position.y,
                this.zones[i].zone.position.width,
                this.zones[i].zone.position.height,
                viewport));
              this.zones[i].hasCoord = true;
            } catch (e) {
              console.log('setPosition error', e);
            }
          }
        }
      }
    },
    updateScale($event) {
      this.scale = $event;
      this.setPosition($event);
    },
    deleteZone(zone) {
      this.$emit('update:zones', this.zones.filter(item => !_isEqual(item, zone)));
      this.paraphsSelected = this.paraphsSelected.filter((item) => item.userId !== zone.zone.user.userId);
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async restorePreviousZone() {
      if (this.previousZone !== undefined) {
        const previousZoneId = this.previousZone.id;
        const index = this.zones.findIndex(elem => elem.id === previousZoneId);
        if (index >= 0) {
          this.$set(this.zones, index, this.previousZone);
          this.previousZone = undefined;
          this.$emit('update:scrollToZone', undefined);
          await this.delay(200);
          this.scrollToClass(`zone-${previousZoneId}`);
        }
      }
    },
    onClose() {
      if (this.previousZone !== undefined) {
        this.restorePreviousZone();
      }
      this.closePopover();
    },
    onReferenceHidden() {
      this.currentZone = undefined;
      this.popoverContent = 0;
    },
    closePopover() {
      this.showPopover = false;
      this.popoverContent = 0;
    },
    addZone(evt) {
      if (this.routeName.startsWith('paraphers.create') && !this.isPopoverGuideOpened) {
        // popover display
        if (!this.showPopover) {
          this.updateCurrentPage(evt.page);
          this.position.token = undefined;
          this.position.positionType = 'manual';
          this.showPopover = true;
          // popover position
          // get cursor's position
          if (evt.x + this.defaultSizeSign.width > Math.floor(this.viewport.width / PIXEL_RATIO)) {
            this.position.x = (this.viewport.width / PIXEL_RATIO) - this.defaultSizeSign.width;
          } else {
            this.position.x = Math.round(evt.x);
          }
          if (evt.y + this.defaultSizeSign.height > Math.floor(this.viewport.height / PIXEL_RATIO)) {
            this.position.y = (this.viewport.height / PIXEL_RATIO) - this.defaultSizeSign.height;
          } else {
            this.position.y = Math.round(evt.y);
          }
          this.position.page = evt.page;
        } else {
          if (this.position.positionType === 'semi-automatic') {
            this.restorePreviousZone();
          }
          this.closePopover();
        }
      }
    },
    getPagePoint(x, y, width, height, viewport = this.viewport) {
      if (viewport) {
        return viewport.convertToPdfPoint(x, y)
          .concat(viewport.convertToPdfPoint(x + width, y + height));
      }
    },
    createSignature(user = undefined, isHidden = false) {
      const pagePoint = this.getPagePoint(this.position.x, this.position.y, this.defaultSizeSign.width, this.defaultSizeSign.height);
      return {
        id: Math.random().toString(36).slice(4),
        signerOnlyValidate: false,
        isHidden: isHidden,
        zone: {
          zoneType: 'signature',
          user: user,
          pagePoint,
          position: {
            docId: this.id,
            fieldsToHide: [],
            applyToEachPage: false,
            mandatSignatureFieldsToShow: [],
            page: this.position.page,
            height: this.defaultSizeSign.height,
            width: this.defaultSizeSign.width,
            x: this.position.x,
            y: this.position.y,
            viewport: undefined,
            token: this.position.token ? this.position.token : undefined,
            positionType: this.position.positionType ? this.position.positionType : 'manual',
          },
        },
      };
    },
    createTextZone() {
      return {
        id: Math.random().toString(36).slice(4),
        signerOnlyValidate: false,
        isHidden: false,
        zone: {
          zoneType: 'textfield',
          name: '',
          pagePoint: this.getPagePoint(this.position.x, this.position.y, this.defaultSizeText.width, this.defaultSizeText.height),
          fontSize: 12,
          position: {
            docId: this.id,
            page: this.position.page,
            height: this.defaultSizeText.height,
            width: this.defaultSizeText.width,
            x: this.position.x,
            y: this.position.y,
            viewport: undefined,
          },
        },
      };
    },
    onAddSignatureZone(zone) {
      const index = this.zones.findIndex(elem => elem.id === zone.id);
      zone.zone.position.viewport = this.getViewport(this.currentPage);
      if (zone.zone.position.page !== this.currentPage || zone.zone.position.positionType === 'semi-automatic') {
        const { x, y, width, height } = this.checkZone(this.position.x, this.position.y, this.defaultSizeSign.width, this.defaultSizeSign.height, zone.zone.position.viewport, true);
        zone.zone.position.page = this.currentPage;
        zone.zone.position.x = x;
        zone.zone.position.y = y;
        zone.zone.pagePoint = this.getPagePoint(x, y, width, height);
      }
      if (index >= 0) {
        this.$set(this.zones, index, zone);
      } else {
        this.zones.push(zone);
      }
      this.$emit('update:zones', this.zones);
      this.currentZone = undefined;
      this.closePopover();
    },
    resizeSignZone({ id }) {
      const index = this.zones.findIndex(elem => elem.id === id);
      const viewport = this.getViewport(this.zones[index].zone.position.page);
      const { x, y, width, height } = this.convertPdfRectangleToViewportPosition(viewport, this.zones[index].zone.pagePoint);
      this.$set(this.zones[index].zone.position, 'x', x);
      this.$set(this.zones[index].zone.position, 'y', y);
      this.$set(this.zones[index].zone.position, 'height', height);
      this.$set(this.zones[index].zone.position, 'width', width);
      this.$set(this.zones[index].zone.position, 'viewport', viewport);
      this.$emit('update:zones', this.zones);
    },
    shouldScrollToZone(zone) {
      const container = this.$refs.pdfViewer.$el.querySelector('.eui-o-pdfdocument');
      if (this.$refs[`zone-${zone.id}`] && this.$refs[`zone-${zone.id}`][0]) {
        const element = this.$refs[`zone-${zone.id}`][0].$el;
        const { top } = element.getBoundingClientRect();
        const { top: containerTop } = container.getBoundingClientRect();
        return top <= containerTop + 10;
      }
      return false;
    },
    async onUpdateSignZone(zone) {
      this.currentZone = zone;
      const { x, y, width, height } = this.convertPdfRectangleToViewportPosition(this.viewport, zone.zone.pagePoint);
      if (y < 10) {
        this.position.y = y + height;
      } else {
        if (this.shouldScrollToZone(zone)) {
          this.$emit('update:scrollToZone', zone.id);
          await this.delay(400);
        }
        this.position.y = y;
      }
      this.position.x = x + (width / 2);
      this.position.page = zone.zone.position.page;
      this.popoverContent = 1;
      this.showPopover = false;
      this.$nextTick(() => {
        this.showPopover = true;
      });
    },
    onDuplicate(zone) {
      const clonedZone = cloneDeep(zone);
      const position = cloneDeep(zone.zone.position);
      if (position.y + position.height * 2 < Math.floor(position.viewport.height / PIXEL_RATIO)) {
        position.y = position.y + position.height;
      } else {
        position.y = position.y - position.height;
      }
      clonedZone.id = Math.random().toString(36).slice(4);
      clonedZone.zone.position = position;
      clonedZone.zone.pagePoint = this.getPagePoint(position.x, position.y, position.width, position.height, position.viewport);
      this.onUpdateTextZone(clonedZone);
    },
    async onUpdateTextZone(zone) {
      this.currentZone = zone;
      const { x, y, width, height } = this.convertPdfRectangleToViewportPosition(this.viewport, zone.zone.pagePoint);
      if (y < 10) {
        this.position.y = y + height;
      } else {
        if (this.shouldScrollToZone(zone)) {
          this.$emit('update:scrollToZone', zone.id);
          await this.delay(400);
        }
        this.position.y = y;
      }
      this.position.x = x + (width / 2);
      this.position.page = zone.zone.position.page;
      this.popoverContent = 3;
      this.showPopover = false;
      this.$nextTick(() => {
        this.showPopover = true;
      });
    },
    onUpdateParaph(zone, viewport, pagePoint) {
      const rect = viewport.clone({scale: this.scale}).convertToViewportRectangle(pagePoint);
      this.currentZone = zone[0].zone;
      switch (zone.align) {
        case 'left':
          this.position.alignment = 'leading';
          break;
        case 'center':
          this.position.alignment = 'center';
          break;
        case 'right':
          this.position.alignment = 'trailing';
          break;
      }
      this.position.x = this.viewport.width / 2;
      this.position.y = this.viewport.height - ((this.viewport.height * (zone[0].zone.position.y * 100)) / 100) - Math.abs(Math.round(rect[3] - rect[1]));
      this.position.page = this.currentPage;
      this.isParaphEditorOpen = true;
    },
    onAddInitialsZone(evt) {
      this.bandAlign = evt.align;
      let initials = this.paraphZones;
      let oldPosition;
      if (initials.length > 0) {
        const lastInitialsZone = initials[initials.length - 1].zone;
        oldPosition = {
          y: lastInitialsZone.position.y,
          dragStart: lastInitialsZone.drag ? lastInitialsZone.drag.start : 0,
          dragEnd: lastInitialsZone.drag ? lastInitialsZone.drag.end : 0,
        };
      }
      let tmpZones = this.zones.filter((zone) => {
        return zone.zone.zoneType !== 'paraph' || (zone.zone.zoneType === 'paraph' && zone.zone.position.docId !== this.id);
      });
      tmpZones = tmpZones.concat(this.updateParaphs(evt.zones, oldPosition));
      this.isParaphEditorOpen = false;
      this.$emit('update:zones', tmpZones);
    },
    sortParaphs(paraphs) {
      let tmpZones = this.zones.filter((zone) => {
        return zone.zone.zoneType !== 'paraph';
      });
      let oldPosition = {
        y: paraphs[0].zone.position.y,
        dragStart: paraphs[0].zone.drag.start,
        dragEnd: paraphs[0].zone.drag.last,
      };
      tmpZones = tmpZones.concat(this.updateParaphs(paraphs, oldPosition));
      this.$emit('update:zones', tmpZones);
    },
    updateParaphs(paraphs, oldPosition) {
      let tmpZones = [];
      paraphs.forEach((paraph, index) => {
        let xRel;
        switch (this.bandAlign) {
          case 'left':
            xRel = 16 + (Number(index) * this.defaultSizeInitials.width);
            break;
          case 'right':
            xRel = this.viewport.width - (Number(index) * this.defaultSizeInitials.width) - this.defaultSizeInitials.width;
            break;
          default:
            xRel = ((this.viewport.width / 2) - (this.defaultSizeInitials.width / 2) - (Number(index) * this.defaultSizeInitials.width));
        }
        if (paraph.zone) {
          paraph.pagePoint = this.getPagePoint(
            xRel,
            paraph.zone.pagePoint.y,
            paraph.zone.pagePoint.width,
            paraph.zone.pagePoint.height,
            paraph.zone.position.viewport,
          );
          switch (this.bandAlign) {
            case 'left':
              paraph.zone.position.alignment = 'leading';
              break;
            case 'center':
              paraph.zone.position.alignment = 'center';
              break;
            case 'right':
              paraph.zone.position.alignment = 'trailing';
              break;
          }
          paraph.zone.position.x = +Number((((100 * xRel) / paraph.zone.position.viewport.width) / 100)).toFixed(2);
          paraph.zone.position.y = +Number((paraph.zone.position.y)).toFixed(2);
          paraph.zone.position.height = ZONE_INITIALS_DEFAULT_HEIGHT;
          paraph.zone.position.width = ZONE_INITIALS_DEFAULT_WIDTH;
          tmpZones.push(paraph);
        } else {
          const viewport = this.getViewport(this.position.page);
          let newParaph = {
            id: Math.random().toString(36).slice(4),
            zone: {
              pagePoint: oldPosition ? this.getPagePoint(
                xRel,
                oldPosition.y,
                this.defaultSizeInitials.width,
                this.defaultSizeInitials.height,
                viewport,
              ) : this.getPagePoint(
                xRel,
                1,
                this.defaultSizeInitials.width,
                this.defaultSizeInitials.height,
                viewport,
              ),
              position: {
                docId: this.id,
                y: oldPosition ? +Number((oldPosition.y)).toFixed(2) : 0,
                x: +Number((((100 * xRel) / viewport.width) / 100)).toFixed(2),
                height: ZONE_INITIALS_DEFAULT_HEIGHT,
                width: ZONE_INITIALS_DEFAULT_WIDTH,
                startingPage: null, // this.position.page,
                viewport,
              },
              drag: {
                end: oldPosition ? oldPosition.dragEnd : 0,
                last: oldPosition ? oldPosition.dragStart : 0,
                start: oldPosition ? oldPosition.dragStart : 0,
              },
              page: this.position.page,
              scale: this.scale,
              zoneType: 'paraph',
              user: paraph.zone ? paraph.zone.user : paraph,
            },
          };
          switch (this.bandAlign) {
            case 'left':
              newParaph.zone.position.alignment = 'leading';
              break;
            case 'center':
              newParaph.zone.position.alignment = 'center';
              break;
            case 'right':
              newParaph.zone.position.alignment = 'trailing';
              break;
          }
          tmpZones.unshift(newParaph);
        }
      });
      return tmpZones;
    },
    moveParaphs({ y, start, end }) {
      this.paraphZones.forEach((zone) => {
        this.$set(zone.zone.position, 'y', y / 100);
        this.$set(zone.zone.drag, 'start', start);
        this.$set(zone.zone.drag, 'end', end);
      });
      this.$emit('update:zones', this.zones);
    },
    onAddTextZone(zone) {
      const index = this.zones.findIndex(elem => elem.id === zone.id);
      zone.zone.position.viewport = this.getViewport(zone.zone.position.page);
      if (index >= 0) {
        this.$set(this.zones, index, zone);
      } else {
        this.zones.push(zone);
      }
      this.$emit('update:zones', this.zones);
      this.currentZone = undefined;
      this.closePopover();
    },
    onAddCaptiveZone(evt, type) {
      if (!type) return;
      this.showPopover = false;
      switch (type) {
        case 'signature':
          this.popoverContent = 1;
          this.currentZone = this.createSignature();
          this.$nextTick(() => {
            this.showPopover = true;
          });
          break;
        case 'paraph':
          this.isParaphEditorOpen = true;
          this.onClose();
          break;
        case 'textfield':
          this.popoverContent = 3;
          this.currentZone = this.createTextZone();
          this.$nextTick(() => {
            this.showPopover = true;
          });
          break;
      }
    },
    setNewOrder(newOrder) {
      this.$emit('drag-detected', newOrder);
    },
  },
  created() {
    // PDFPageProxy#getViewport
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    // this.viewport = this.pages[this.currentPage - 1].getViewport({ scale: this.scale });
    const dontShowAddSignatureGuideAgain = preferences.getDontShowAddSignatureGuideAgain();
    if (dontShowAddSignatureGuideAgain !== null) {
      this.isPopoverGuideOpened = !dontShowAddSignatureGuideAgain;
    } else {
      this.isPopoverGuideOpened = true;
    }
  },
  mounted() {
    // handle paraph alignment for PDFBand
    let paraphBandAlign = 'center';
    let paraphMinX = 0.5;
    let paraphMaxX = 0.5;
    for (const sign of this.zones) {
      const zone = sign.zone;
      if (!zone.zoneType === 'paraph') continue;
      if (zone.position.x > paraphMaxX) paraphMaxX = zone.position.x;
      if (zone.position.x < paraphMinX) paraphMinX = zone.position.x;
    }
    if (paraphMaxX > 0.9) paraphBandAlign = 'right';
    if (paraphMinX < 0.1) paraphBandAlign = 'left';

    // add alignment on each zone's position
    for (const sign of this.zones) {
      sign.zone.position.alignment = paraphBandAlign === 'right' ? 'trailing' : paraphBandAlign === 'left' ? 'leading' : 'center';
    };

    this.bandAlign = paraphBandAlign;

    this.$emit('update:zones', this.zones);
  }
};
</script>
