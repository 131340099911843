<template>
  <svg class="eui-o-advancedPDFViewer__icon"
       :class="`-${color}`"
       height="100%"
       width="100%"
       viewBox="0 0 50 50"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M1.46485 11.1558C2.27384 11.1558 2.92971 10.5 2.92971 9.69099V2.92971H9.69099C10.5 2.92971 11.1558 2.27384 11.1558 1.46485C11.1558 0.655864 10.5 0 9.69099 0H1.46485C0.655864 0 0 0.655864 0 1.46485V9.69099C0 10.5001 0.655864 11.1558 1.46485 11.1558Z"/>
    <path d="M48.5357 0H40.3096C39.5006 0 38.8447 0.655864 38.8447 1.46485C38.8447 2.27384 39.5006 2.92971 40.3096 2.92971H47.0709V9.69099C47.0709 10.5 47.7267 11.1558 48.5357 11.1558C49.3447 11.1558 50.0006 10.5 50.0006 9.69099V1.46485C50.0006 0.655864 49.3447 0 48.5357 0Z"/>
    <path d="M48.5357 38.8438C47.7267 38.8438 47.0709 39.4996 47.0709 40.3086V47.0699H40.3096C39.5006 47.0699 38.8447 47.7257 38.8447 48.5347C38.8447 49.3437 39.5006 49.9996 40.3096 49.9996H48.5357C49.3447 49.9996 50.0006 49.3437 50.0006 48.5347V40.3086C50.0006 39.4995 49.3447 38.8438 48.5357 38.8438Z"/>
    <path d="M9.69099 47.0699H2.92971V40.3086C2.92971 39.4996 2.27384 38.8438 1.46485 38.8438C0.655864 38.8438 0 39.4996 0 40.3086V48.5347C0 49.3437 0.655864 49.9996 1.46485 49.9996H9.69099C10.5 49.9996 11.1558 49.3437 11.1558 48.5347C11.1558 47.7257 10.5001 47.0699 9.69099 47.0699Z"/>
    <path d="M40.9831 18.6895V9.43848C40.9831 8.9795 40.6111 8.60742 40.1511 8.60742H25.0006H9.8501C9.39014 8.60742 9.01807 8.9795 9.01807 9.43848V18.6895C9.01807 19.1495 9.39014 19.5216 9.8501 19.5216H14.2681C14.7271 19.5216 15.0992 19.1495 15.0992 18.6895V15.5206C15.0992 15.0606 15.4722 14.6885 15.9312 14.6885H21.1275C21.5875 14.6885 21.9596 15.0606 21.9596 15.5206V38.386C21.9596 38.8459 21.5875 39.218 21.1275 39.218H18.8551C18.3951 39.218 18.023 39.5901 18.023 40.05V44.468C18.023 44.927 18.3951 45.2991 18.8551 45.2991H25.0006H31.1462C31.6061 45.2991 31.9782 44.927 31.9782 44.468V40.05C31.9782 39.5901 31.6061 39.218 31.1462 39.218H28.8737C28.4137 39.218 28.0416 38.8459 28.0416 38.386V15.5206C28.0416 15.0606 28.4137 14.6885 28.8737 14.6885H34.07C34.529 14.6885 34.902 15.0606 34.902 15.5206V18.6895C34.902 19.1495 35.2741 19.5216 35.7331 19.5216H40.1511C40.6111 19.5216 40.9831 19.1495 40.9831 18.6895Z"/>
  </svg>
</template>

<script>
export default {
  name: 'TextIcon',
  props: {
    color: { type: String, default: 'grey' },
  },
};
</script>
