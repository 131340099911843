<template>
  <EuiGridRow style="max-height: 816px;">
    <EuiGridColumn class="panel" :class="{ '-enlarge': isEnlarge }" width="1/4">
    <EuiPDFPreview :pdfsList="Signbook.documents"
                   ref="pdfPreview"
                   :title="$t('paraphers.create.addSignatures.title')"
                   :pages.sync="pages"
                   :currentPdfIndex.sync="currentPdfIndex"
                   :currentPage.sync="currentPage"
                   :pageCount="pageCount"
                   :signsList.sync="Signbook.signsList"
                   @page-change="currentPage = $event"/>
    </EuiGridColumn>
    <EuiGridColumn class="pdfViewer" :class="{ '-enlarge': isEnlarge }" width="2/4">
    <div class="eds-advancedPDFViewer__container" v-if="Signbook.documents.length > 0 && base64">
      <EuiAdvancedPDFViewer :title="Signbook.documents[currentPdfIndex].name"
                            :pdf="base64"
                            :scrollToZone.sync="zoneId"
                            base64
                            :id="Signbook.documents[currentPdfIndex].id"
                            :currentPage.sync="currentPage"
                            :zones.sync="Signbook.signsList"
                            :suggestions="propositions"
                            @update:zones="onUpdateZones"
                            @errors="onerror"
                            @document-rendered="onDocumentRendered"
                            @page-count="updatePageCount"
                            @scale-change="updateScale"
                            @enlarge="enlargePdf"
                            :isEnlarge="isEnlarge"
                            :buffer="false"
                            :signatoriesToDisplay="signatoriesToDisplay"
                            v-if="tmpTemplate"
                            ref="pdf"
                            :shouldUpdatePosition="Signbook.shouldUpdatePosition"/>
    </div>
  </EuiGridColumn>
    <EuiGridColumn class="panel" :class="{ '-enlarge': isEnlarge }" width="1/4">
      <SignatoriesList @scrollToZone="scrollToZone"
                       :title="$t('paraphers.create.heading.signatories')"
                       :items="Signbook.signatories"
                       :documents="Signbook.documents"
                       style="height: 100%;"
                       :signatoriesToDisplay="signatoriesToDisplay"
                       @update:signatoriesToDisplay="signatoriesToDisplay = $event"/>
    </EuiGridColumn>
  </EuiGridRow>
</template>
<script>

import {mapActions, mapState} from 'vuex';
import EuiAdvancedPDFViewer from '../../../components/AdvancedPDFViewer/AdvancedPDFViewer';
import EuiPDFPreview from '../../../components/AdvancedPDFViewer/_internal/PDFPreview';
import SignatoriesList from '../../../pages/paraphers/create/SignatoriesList';
import { FileUtil } from '../../../utils/file';

export default {
  name: 'CreateParapherAddSignatures',
  inject: ['Signbook'],
  watch: {
    async currentPdfIndex(value) {
      this.base64 = await FileUtil.getBase64(this.Signbook.documents[value].fileContent);
    },
    currentPage(value) {
      this.$refs['pdfPreview'].showDetails = true;
      this.$refs['pdfPreview'].$refs[`scrollingDocument-${this.$refs['pdfPreview']._uid}`].scrollToPage(value);
    },
  },
  computed: {
    propositions() {
      return this.Signbook.signatories.map((user) => ({
        fullname: `${user.fullname}`,
        initials: `${user.initials}`,
        email: `${user.email}`,
        userId: `${user.userId}`,
      }));
    },
    ...mapState({
      routeName: (state) => state.route.name,
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
  },
  data() {
    return {
      isEnlarge: false,
      pdfsList: [],
      zoneId: undefined,
      docOpened: undefined,
      base64: undefined,
      pages: undefined,
      isPreviewEnabled: false,
      currentPdfIndex: 0,
      currentPage: 1,
      pageCount: undefined,
      captiveZones: undefined,
      scale: 1,
      signatoriesToDisplay: undefined,
      // boolean pour s'assurer que toutes les positions utilisées par un template sont correctement renseignées avant d'afficher le pdfViewer
      tmpTemplate: false,
      missTokenSignsIndex: [],
      alreadyBuild: false,
    };
  },
  components: {
    // EuiAdvancedPDFViewer: () => import(/* webpackChunkName: "PDFViewer" */ '@/components/AdvancedPDFViewer/AdvancedPDFViewer'),
    // EuiPDFPreview: () => import(/* webpackChunkName: "PDFPreview" */ '@silae/edoc-ui/src/components/organisms/AdvancedPDFViewer/_internal/PDFPreview'),
    // SignatoriesList: () => import(/* webpackChunkName: "PDFPreview" */ '@/pages/paraphers/create/SignatoriesList'),
    EuiAdvancedPDFViewer,
    EuiPDFPreview,
    SignatoriesList,
  },
  methods: {
    ...mapActions({
      searchFieldOnPDF: 'ModuleEdocSign/parapheurs/searchFieldOnPDF',
      pushAlert: 'application/pushAlert',
    }),
    enlargePdf() {
      this.$emit('enlargePdf');
      this.isEnlarge = !this.isEnlarge;
    },
    scrollToZone(zone) {
      this.currentPdfIndex = this.Signbook.documents.findIndex(doc => doc.id === zone.docId);
      setTimeout(() => {
        if (zone.zoneType === 'paraph') {
          this.$refs.PDFViewer.scrollToClass('eui-o-advancedPDFViewer__band');
        } else {
          this.zoneId = zone.zoneId;
        }
      }, 100);
    },
    onerror(errors) {
      for (let i = 0; i < errors.length; i++) {
        this.pushAlert({
          message: errors[i].message,
          timeout: 5000,
          type: 'error',
        });
      }
      console.log('ERROR', errors);
    },
    onUpdateZones(zones) {
      this.Signbook.signatories.forEach(signatory => {
        const signatures = zones.filter(zone => zone.zone.user.userId === signatory.userId && zone.zone.zoneType === 'signature');
        const paraphs = zones.filter(zone => zone.zone.user.userId === signatory.userId && zone.zone.zoneType === 'paraph');
        const texts = zones.filter(zone => zone.zone.user.userId === signatory.userId && zone.zone.zoneType === 'textfield');
        this.$set(signatory, 'signatures', signatures);
        this.$set(signatory, 'paraphs', paraphs);
        this.$set(signatory, 'texts', texts);
      });
    },
    onDocumentRendered(pages) {
      this.pages = pages;
    },
    updatePageCount(pageCount) {
      this.pageCount = pageCount;
    },
    updateScale($event) {
      this.scale = $event;
    },
    buildMissTokenSigns() {
      if (this.missTokenSignsIndex.length) {
        let missTokens = [];
        this.missTokenSignsIndex.forEach((index) => {
          if (this.Signbook.signsList[index].zone.position.docIndex === this.currentPdfIndex && !this.Signbook.signsList[index].zone.alreadyBuild) {
            const tmpSign = this.Signbook.signsList[index];
            const { zone } = tmpSign;
            const position = zone.position;
            missTokens.push(position.token);
          }
        });
      }
    },
    async setPosition() {
      this.missTokenSignsIndex = [];
      // handle regular sign zones
      for (const sign of this.Signbook.signsList) {
        if (!sign.zone.missToken && !sign.zone.alreadyBuild) {
          const zone = sign.zone;
          const position = zone.position;
          if (position.positionType === 'semi-automatic') {
            const document = this.Signbook.documents.find(doc => doc.id === position.docId);
            const fileContent = await FileUtil.getBase64(document.fileContent);
            const {coordinates} = await this.searchFieldOnPDF({wordToFind: position.token, fileContent});
            if (coordinates === null) {
              this.missTokenSignsIndex.push(position.token);
              position.missToken = true;
              position.x = 50 + (10 * this.missTokenSignsIndex.length);
              position.y = 50 + (10 * this.missTokenSignsIndex.length);
              position.width = 180;
              position.height = 50;
            } else {
              position.missToken = false;
              position.page = coordinates.page;
              position.x = coordinates.x;
              position.y = coordinates.y + 25;
              position.width = 180;
              position.height = 50;
              // this.$refs.PDFViewer.setPosition();
              zone.alreadyBuild = true;
            }
          }
        }
      }
      this.missTokenSignsIndex.forEach((token) => {
        this.onerror([{ message: this.$t('paraphers.create.addSignatures.error.semiAutoTermNotFound.title', { name: token }) }]);
      });
    },
  },
  async created() {
    if (this.Signbook.documents && this.Signbook.documents[this.currentPdfIndex]) {
      this.base64 = await FileUtil.getBase64(this.Signbook.documents[this.currentPdfIndex].fileContent);
    }
    if (this.routeName.startsWith('templates.use')) {
      await this.setPosition();
    }
    this.tmpTemplate = true;
  },
};
</script>

<style scoped>
.eui-o-pdfPreview__details {
  padding-bottom: 3rem;
}

.pdfViewer {
  transition: all 0.2s ease-in-out;
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.pdfViewer.-enlarge {
  transition: all 0.2s ease-in-out;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.panel {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.panel.-enlarge {
  transition: all 0.2s ease-in-out;
  flex: 0 0 0% !important;
  max-width: 0% !important;
  padding: 0 !important;
}
</style>
