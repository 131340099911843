<template>
  <div>
    <div class="eui-o-advancedPDFViewer__addZoneHeader">
      <EuiHeading :level="3">{{ $t('paraphers.create.heading.addInputField') }}</EuiHeading>
      <EuiButton iconOnly color="primary" size="medium" @click="closePopover">
        <EuiIcon name="close"/>
      </EuiButton>
    </div>
    <div class="eui-o-advancedPDFViewer__addZoneContent">
      <EuiGrid>
        <!-- sign -->
        <EuiGridRow>
          <EuiHeading :level="4">
            {{ $t('paraphers.create.heading.signature') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn class="eui-o-advancedPDFViewer__popoversvg"
                         verticalAlign="center">
            <SignIcon/>
          </EuiGridColumn>
          <EuiGridColumn>
            <p class="eui-o-advancedPDFViewer__popoverlabel">
              {{ $t('paraphers.create.label.addSignatureZone') }}
            </p>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiButton class="eui-o-advancedPDFViewer__popoverbutton"
                     variant="raised"
                     color="primary"
                     size="small"
                     @click="addZone($event, 'signature')">
            {{ $t('button.add') }}
          </EuiButton>
        </EuiGridRow>
        <!-- initials -->
        <EuiGridRow>
          <EuiHeading :level="4">
            {{ $t('paraphers.create.heading.initials') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn class="eui-o-advancedPDFViewer__popoversvg" verticalAlign="center"><ParaphIcon/></EuiGridColumn>
          <EuiGridColumn>
            <p class="eui-o-advancedPDFViewer__popoverlabel">
              {{ $t('paraphers.create.label.addInitialZone') }}
            </p>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiButton class="eui-o-advancedPDFViewer__popoverbutton"
                     variant="raised"
                     color="primary"
                     size="small"
                     @click="addZone($event, 'paraph')">
            {{ $t('button.add') }}
          </EuiButton>
        </EuiGridRow>
        <!-- text -->
        <EuiGridRow>
          <EuiHeading :level="4">
            {{ $t('paraphers.create.heading.text') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn class="eui-o-advancedPDFViewer__popoversvg" verticalAlign="center">
            <TextIcon/>
          </EuiGridColumn>
          <EuiGridColumn>
            <p class="eui-o-advancedPDFViewer__popoverlabel">
              {{ $t('paraphers.create.label.addTextZone') }}
            </p>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiButton class="eui-o-advancedPDFViewer__popoverbutton"
                     variant="raised"
                     color="primary"
                     size="small"
                     @click="addZone($event, 'textfield')">
            {{ $t('button.add') }}
          </EuiButton>
        </EuiGridRow>
      </EuiGrid>
    </div>
  </div>
</template>

<script>
import SignIcon from '../svg/SignIcon';
import TextIcon from '../svg/TextIcon';
import ParaphIcon from '../svg/ParaphIcon';

export default {
  name: 'PDFPopoverDefault',
  components: { SignIcon, TextIcon, ParaphIcon },
  methods: {
    addZone(e, type) {
      this.$emit('addCaptiveZone', e, type);
    },
    closePopover() {
      this.$emit('close');
    },
  },
};
</script>
