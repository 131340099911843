<template>
  <EuiBlock class="eds-signatoriesList" :title="title">
    <div class="eds-signatoriesList__container" :style="styleObject">
      <ol class="eds-signatoriesList__list">
        <li v-for="(item, index) in items"
            :key="index"
            class="eds-signatoriesList__itemTitle"
            :class="`${focusStyle(item.userId)}`"
            @click="toggle(index)"
            @mouseenter="$emit('update:signatoriesToDisplay', item.userId)"
            @mouseleave="$emit('update:signatoriesToDisplay', undefined)">
          <EuiIcon class="eui-u-text-blue-grey eui-u-mr-4" size="small" color="primary" name="visualiser"/>
          <EuiIcon class="text-blue-grey eui-u-mr-3" name="person" size="small"/>
          <div class="eui-u-flex-1">{{ item.fullname }}</div>
          <EuiBadge color="error" :content="totalInvalid(selectedItem)" :max="99" inverted />
          <EuiBadge color="success" :content="total(index)" :max="99" inverted />
          <EuiIcon v-if="total(index) > 0" name="arrow_right" size="medium"/>
        </li>
      </ol>
      <div class="eds-signatoriesList__details">
        <button class="eds-signatoriesList__button" type="button" @click="toggle(undefined)">
          <EuiIcon name="arrow_left" size="medium"/>
          <EuiIcon class="text-blue-grey eui-u-mr-3" name="person" size="small"/>
          <div class="eui-u-flex-1">
            <template v-if="items[selectedItem]">
              {{ selectedItem + 1 }} - {{ items[selectedItem].fullname }}
            </template>
          </div>
          <EuiBadge color="error" :content="totalInvalid(selectedItem)" :max="99" inverted />
          <EuiBadge color="success" :content="total(selectedItem)" :max="99" inverted />
        </button>
        <EuiExpansionPanel :opened="true" v-if="signatures.length > 0">
          <template #header>{{ $t('paraphers.create.heading.signatures') }}</template>
          <template #actions>
            <div class="eds-signatoriesList__counter">{{ signatures.length }}</div>
            <EuiButton icon-only class="eui-m-expansionPanel__indicator">
              <EuiIcon name="chevron_down" color="primary"/>
            </EuiButton>
          </template>
          <EuiList>
            <EuiListItem>
                <EuiCheckbox class="my-0" v-model="items[selectedItem].signerOnlyValidate">
                  <div>
                    <span v-html="$t('paraphers.create.label.signerOnlyValidate')"></span>
                    <br>
                    <span v-html="$t('paraphers.create.label.signerOnlyValidateItalic')"></span>
                  </div>
                </EuiCheckbox>
              <EuiTooltip class="eui-u-mt-1"
                          :content="$t('paraphers.create.tooltip.signerOnlyValidate')"
                          placement="top">
                <template slot="anchor">
                  <EuiIcon name="infosperso" color="primary"/>
                </template>
              </EuiTooltip>
            </EuiListItem>
            <EuiListItem :key="signature.id"
                         :button="true"
                         v-for="signature in signatures"
                         @click="scrollToZone(signature)">
              <template #graphic>
                <EuiIcon class="eui-u-text-blue-grey" name="file"/>
              </template>
              <span :class="{'eui-u-text-red' : signature.zone.position.missToken}">{{ zoneTitle(signature) }}</span>
            </EuiListItem>
          </EuiList>
        </EuiExpansionPanel>
        <EuiDivider class="eui-u-my-0" secondary v-if="signatures.length > 0"/>
        <EuiExpansionPanel :opened="true" v-if="paraphs.length > 0">
          <template #header>{{ $t('paraphers.create.heading.initials') }}</template>
          <template #actions>
            <div class="eds-signatoriesList__counter">{{ paraphs.length }}</div>
            <EuiButton icon-only class="eui-m-expansionPanel__indicator">
              <EuiIcon name="chevron_down" color="primary"/>
            </EuiButton>
          </template>
          <EuiList>
            <EuiListItem :key="paraph.id"
                         v-for="paraph in paraphs"
                         :button="true"
                         @click="scrollToZone(paraph)">
              <template #graphic>
                <EuiIcon class="eui-u-text-blue-grey" name="file"/>
              </template>
              {{ zoneTitle(paraph) }}
            </EuiListItem>
          </EuiList>
        </EuiExpansionPanel>
        <EuiDivider class="eui-u-my-0" secondary v-if="paraphs.length > 0"/>
        <EuiExpansionPanel :opened="true" v-if="texts.length > 0">
          <template #header>{{ $t('paraphers.create.heading.texts') }}</template>
          <template #actions>
            <div class="eds-signatoriesList__counter">{{ texts.length }}</div>
            <EuiButton icon-only class="eui-m-expansionPanel__indicator">
              <EuiIcon name="chevron_down" color="primary"/>
            </EuiButton>
          </template>
          <EuiList>
            <EuiListItem :key="text.id"
                         :button="true"
                         @click="scrollToZone(text)"
                         v-for="text in texts">
              <template #graphic>
                <EuiIcon class="eui-u-text-blue-grey" name="file"/>
              </template>
              {{ zoneTitle(text) }}
            </EuiListItem>
          </EuiList>
        </EuiExpansionPanel>
        <EuiDivider class="eui-u-my-0" secondary v-if="texts.length > 0"/>
      </div>
    </div>
  </EuiBlock>
</template>

<script>
export default {
  name: 'SignatoriesList',
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    signatoriesToDisplay: {
      default: undefined,
    },
  },
  watch: {
    items: {
      handler(newValue, oldValue) {
        if (this.total(this.selectedItem) === 0) {
          this.showDetails = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    styleObject() {
      return {
        transform: this.showDetails ? 'translateX(-50%) translateZ(0px)' : 'none',
      };
    },
    signatures() {
      if (this.items[this.selectedItem]) {
        return this.items[this.selectedItem].signatures &&
        this.items[this.selectedItem].signatures.length > 0
          ? this.items[this.selectedItem].signatures : [];
      }
      return [];
    },
    paraphs() {
      if (this.items[this.selectedItem]) {
        return this.items[this.selectedItem].paraphs &&
        this.items[this.selectedItem].paraphs.length > 0
          ? this.items[this.selectedItem].paraphs : [];
      }
      return [];
    },
    texts() {
      if (this.items[this.selectedItem]) {
        return this.items[this.selectedItem].texts &&
        this.items[this.selectedItem].texts.length > 0
          ? this.items[this.selectedItem].texts : [];
      }
      return [];
    },
  },
  data() {
    return {
      showDetails: false,
      selectedItem: 0,
    };
  },
  methods: {
    focusStyle(userId) {
      if (this.signatoriesToDisplay === userId) return '-focus';
      else if (this.signatoriesToDisplay === undefined) return '';
      else return '-hidden';
    },
    scrollToZone(zone) {
      this.$emit('scrollToZone', { docId: zone.zone.position.docId, zoneId: zone.id, zoneType: zone.zone.zoneType });
    },
    zoneTitle(item) {
      if (item && item.zone.position.docId) {
        const document = this.documents.find(document => document.id === item.zone.position.docId);
        if (document) {
          if (item.zone.position.page) {
            return `${document.name} - p.${item.zone.position.page}`;
          }
          return `${document.name}`;
        }
        return '';
      }
      return '';
    },
    total(index) {
      let signaturesValid = 0;
      let paraphs = 0;
      let texts = 0;
      if (this.items[index] && this.items[index].signatures) {
        signaturesValid = this.items[index].signatures.filter((sign) => !sign.zone.position.missToken).length;
      }
      if (this.items[index] && this.items[index].paraphs) {
        paraphs = this.items[index].paraphs.length;
      }
      if (this.items[index] && this.items[index].texts) {
        texts = this.items[index].texts.length;
      }
      return signaturesValid + paraphs + texts;
    },
    totalInvalid(index) {
      let result = 0;
      if (this.items[index] && this.items[index].signatures) {
        result = this.items[index].signatures.filter((sign) => sign.zone.position.missToken).length;
      }
      return result;
    },
    toggle(index) {
      if (index === undefined) {
        this.showDetails = false;
      } else {
        if (this.total(index) > 0 || this.showDetails) {
          this.selectedItem = index;
          this.showDetails = true;
        }
      }
    },
  },
};
</script>
