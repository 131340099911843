<template>
  <form @submit.prevent ref="popover" @keyup.esc="closePopover">
    <div class="eui-o-advancedPDFViewer__addZoneHeader">
      <EuiHeading :level="3">
        {{ $t('paraphers.create.heading.initials') }}
      </EuiHeading>
      <EuiButton iconOnly color="primary" size="medium" @click="closePopover"><EuiIcon name="close"/></EuiButton>
    </div>
    <div class="eui-o-advancedPDFViewer__addZoneContent">
      <EuiGrid>
        <!-- sign -->
        <EuiGridRow>
          <EuiHeading :level="4">
            {{ $t('paraphers.create.heading.associateSignatory') }}
          </EuiHeading>
        </EuiGridRow>
        <EuiGridRow>
          <EuiSearchBar :placeholder="$t('paraphers.searchBar.placeholder')"
                     ref="searchBar"
                     v-model="signerSelectedValue"
                     @change="onSelectItem(signerSelectedValue)"
                     :options="options"
                     clearable
                     clearOnSearch
                     clearOnSelect
                     :emptyMessage="$t('paraphers.searchBar.emptyMessage')"
                     :suggestionsTitle="$t('paraphers.searchBar.suggestionsTitle')">
            <template #autocompleteItem="{ item }">
              <EuiGridRow justify="between">
                <EuiGridColumn width="3/12" verticalAlign="center">
                  <div class="letterAvatar">{{item.initials}}</div>
                </EuiGridColumn>
                <EuiGridColumn width="9/12" class="eui-u-pl-1" verticalAlign="center">
                  <span class="leading-tight break-words eui-u-font-bold">{{item.fullname}}</span>
                  <span class="leading-tight break-words">{{item.email}}</span>
                </EuiGridColumn>
              </EuiGridRow>
            </template>
          </EuiSearchBar>
        </EuiGridRow>
        <div class="eui-o-advancedPDFViewer__signersList">
          <EuiGridRow v-for="(selectedSigner, index) in paraphsTmp"
                      :key="selectedSigner.id"
                      :class="index === 0 ? '' : 'eui-u-mt-5'">
            <EuiGrid class="eui-u-p-0">
              <EuiGridRow justify="between">
                <EuiGridColumn width="2/12" verticalAlign="center">
                  <div class="letterAvatar">
                    {{selectedSigner.initials}}
                  </div>
                </EuiGridColumn>
                <EuiGridColumn width="8/12" class="eui-u-pl-1" verticalAlign="center">
                  <span class="leading-tight break-words eui-u-font-bold">{{selectedSigner.fullname}}</span>
                  <span class="leading-tight break-words">{{selectedSigner.email}}</span>
                </EuiGridColumn>
                <EuiGridColumn width="2/12" horizontalAlign="end" verticalAlign="center">
                  <EuiButton  iconOnly color="primary" size="medium" @click="removeSignerFromList(selectedSigner)">
                    <EuiIcon name="delete"/>
                  </EuiButton>
                </EuiGridColumn>
              </EuiGridRow>
            </EuiGrid>
          </EuiGridRow>
        </div>
        <EuiGridRow><EuiHeading :level="4">{{ $t('paraphers.create.heading.initialsPositioning') }}</EuiHeading></EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiRadio v-model="align" value="left">{{ $t('paraphers.align.left') }}</EuiRadio>
            <EuiRadio v-model="align" value="center">{{ $t('paraphers.align.center') }}</EuiRadio>
            <EuiRadio v-model="align" value="right" selected>{{ $t('paraphers.align.right') }}</EuiRadio>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiDivider secondary/>
        <EuiGridRow>
          <EuiGridColumn verticalAlign="center" horizontalAlign="start">
            <EuiButton variant="text" @click="onClickReset" class="eui-o-advancedPDFViewer__popoverbutton-secondary">
              {{ $t('button.reset') }}
            </EuiButton>
          </EuiGridColumn>
          <EuiGridColumn verticalAlign="center" horizontalAlign="end">
            <EuiButton variant="raised"
                       :disabled="paraphsTmp.length === 0"
                       @click="onClickValidate"
                       color="primary"
                       class="eui-o-advancedPDFViewer__popoverbutton">
              {{ $t('button.validate') }}
            </EuiButton>
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </div>
  </form>
</template>

<script>
import _some from 'lodash.some';

export default {
  name: 'PDFPopoverInitials',
  props: {
    allParaphs: {
      type: Array,
      default: () => [],
    },
    paraphsSelected: {
      type: Array,
      default: () => [],
    },
    bandAlign: {
      type: String,
    },
  },
  data() {
    return {
      paraphsTmp: [],
      signerSelectedValue: '',
      align: 'center',
    };
  },
  computed: {
    options() {
      if (this.paraphsTmp.length === 0) return this.allParaphs;
      const res = this.allParaphs.filter(item => !_some(this.paraphsTmp, item));
      if (res.length > 0) {
        return res;
      }
      return [];
    },
  },
  methods: {
    closePopover() {
      this.$emit('close');
    },
    onClickValidate() {
      if (this.paraphsTmp.length > 0) {
        this.$emit('addInitialsZone', { zones: this.paraphsTmp.slice(0), align: this.align, width: this.$refs.popover.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.offsetWidth });
      }
    },
    onClickReset() {
      this.align = 'center';
      this.signerSelectedValue = '';
      this.paraphsTmp = [];
      this.$refs.searchBar.clear();
    },
    onSelectItem(signerToAdd) {
      this.signerSelectedValue = '';
      this.$refs.searchBar.clear();
      // difference between events emission that fall into this method
      const hasValueProp = signerToAdd.value;
      if (typeof signerToAdd === 'object' && !hasValueProp) {
        this.paraphsTmp.push(signerToAdd);
      }
    },
    removeSignerFromList(signerToRemove) {
      this.paraphsTmp.splice(this.paraphsTmp.findIndex(signer => signer.id === signerToRemove.id), 1);
    },
    handleKeydown(e) {
      if (e.key === 'Enter') {
        if (e.target.nodeName === 'BODY') {
          this.onClickValidate();
        }
        return;
      }
      if (e.key === 'Escape') {
        if (e.target.nodeName === 'BODY') {
          this.closePopover();
        }
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    this.paraphsTmp = JSON.parse(JSON.stringify(this.paraphsSelected)).map(paraph => paraph.zone.user);
    this.align = this.bandAlign;
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>
